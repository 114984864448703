
import React from 'react';
import supabase from '../util/Supabase';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../util/Routes';
import RecordForm from '../components/RecordForm';

const NewRecord = (props) => {

    const auth = useStoreState((state) => state.auth);
    const navigate = useNavigate();

    const onSubmit = async (formData) => {
        const insertData = {
            profile: auth.session.user.id,
            activity: parseInt(formData.activity),
            region: parseInt(formData.region),
            area: formData.area,
            record_date: formData.date,
            duration_minutes: parseInt(formData.hours) * 60 + parseInt(formData.minutes),
            comment: formData.comment
        }

        try {
            const { error } = await supabase.from('records').insert(insertData)

            if (error !== null)
                console.warn(error)

            navigate(Routes.Statistics)
        } catch (e) {
            console.warn(e)
        }
    }

    return (
        <RecordForm onSubmit={onSubmit} />
    )
}

export default NewRecord;