import { Grid, GridItem } from "@chakra-ui/react";
import LatestActivitiesTable from "../components/LatestActivitiesTable";
import UsersList from "../components/UsersList";

const Home = (props) => {
    return <>
        <Grid templateColumns={{ base: '1fr', lg: 'repeat(6, 1fr)' }} gap={{ base: 0, lg: 4 }}>
            <GridItem colSpan={4}>
                <LatestActivitiesTable showDelete={false} showEdit={false} showClone={true} showUser={true} />
            </GridItem>

            <GridItem colSpan={2} borderLeft="gray.200" borderLeftWidth={{base: 0, lg: "1px"}} paddingLeft={{base: 0, lg: 4}}>
                <UsersList />
            </GridItem>
        </Grid>
    </>
}

export default Home;