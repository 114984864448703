import { CopyIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Spacer, Stat, StatHelpText, StatNumber } from "@chakra-ui/react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { GetQueryRoute, Routes } from "../util/Routes";
import { MinutesToHoursAndMinutes } from "../util/Helper";

const ActivityDetailRow = (props) => {

    const navigate = useNavigate()
    const {id, clonable, editable, deletable, date, user, region, name, activity, minutes} = props;

    return (
        <Flex gap={4} align="center" marginBottom={props.marginBottom}>
            <Stat flexGrow={0}>
                <StatNumber style={{ minWidth: "80px", textAlign: "right" }} >{format(date, "d.M.")}</StatNumber>
                { props.showUser && <StatHelpText style={{ textAlign: "center" }} mb={0}>{user}</StatHelpText> }
            </Stat>
            <Flex direction="column">
                <span>{name}</span>
                <span>{region} - {activity} for <strong>{MinutesToHoursAndMinutes(minutes)}</strong></span>
            </Flex>
            <Spacer />
            <Flex gap={1}>
                {clonable && <IconButton size={"sm"} icon={<CopyIcon />} onClick={() => navigate(GetQueryRoute("CloneRecord", "recordId", id))} />}
                {editable && <IconButton size={"sm"} icon={<EditIcon />} onClick={() => navigate(GetQueryRoute("EditRecord", "recordId", id))} />}
                {deletable && <IconButton size={"sm"} icon={<DeleteIcon />} onClick={() => navigate(GetQueryRoute("DeleteRecord", "recordId", id))}/>}
            </Flex>
        </Flex>
    )
}

ActivityDetailRow.defaultProps = {
    id: 0,
    clonable: false,
    editable: false,
    deletable: false, 
    date: new Date(),
    user: "",
    showUser: false,
    region: "Sparta",
    name: "Rage Center",
    activity: "Fighting",
    minutes: 67,
    marginBottom: 4
}

export default ActivityDetailRow;