import { Button, Spinner, Table, TableContainer, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import React from "react";
import supabase from "../util/Supabase";
import { MinutesToDaysHoursAndMinutes } from "../util/Helper";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { Routes } from "../util/Routes";

const Regions = (props) => {

    const [regionData, setRegionData] = React.useState(null)
    const navigate = useNavigate()

    React.useEffect(() => {
        const f = async () => {
            const { data } = await supabase.from("region_overview").select()
            setRegionData(data)
        }
        f()
    }, [])

    return (
        <>
            <h2>Regions</h2>
            <p>You can find all available regions on this page. You may also add new regions if required.</p>
            <div style={{ textAlign: "right" }}>
                <Button marginTop={4} marginBottom={4} onClick={() => navigate(Routes.NewRegion)}>Add New Region</Button>
            </div>
            <h2>Existing Regions</h2>

            {regionData === null ? (
                <Spinner />
            ) : (
                <TableContainer>
                    <Table size='sm'>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th isNumeric># Records</Th>
                                <Th isNumeric>Total Time</Th>
                                <Th isNumeric>Last Record</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {regionData.map((v, i) =>
                                <Tr key={"region-" + i}>
                                    <Td>{v.name}</Td>
                                    <Td isNumeric>{v.total}</Td>
                                    <Td isNumeric>{MinutesToDaysHoursAndMinutes(parseInt(v.total_duration), true)}</Td>
                                    <Td isNumeric>{format(new Date(v.last_record), 'dd.MM.yyyy')}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}

export default Regions;