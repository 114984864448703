
import {
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Alert,
    FormErrorMessage,
} from '@chakra-ui/react'
import SectionHeader from '../components/SectionHeader';
import { useForm } from 'react-hook-form';
import React from 'react';
import supabase from '../util/Supabase';

const UpdatePassword = (props) => {

    const [updateResult, setUpdateResult] = React.useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (formData) => {

        if (formData.password1.length < 6) {
            setUpdateResult({ type: "error", "message": "The password has a minimum length of 6 characters." })
            return;
        }

        if (formData.password1 !== formData.password2) {
            setUpdateResult({ type: "error", "message": "The passwords do not match!" })
            return;
        }

        const { data, error } = await supabase.auth.updateUser({ password: formData.password1 })

        if (error) {
            console.warn(error);
            setUpdateResult({ type: "error", "message": "Update failed!" })
        } else {
            console.log(data)
            setUpdateResult({ type: "success", "message": "Successfully updated password." })
        }
    }

    return <div {...props}>
        <SectionHeader>Update Password</SectionHeader>
        {updateResult !== null && (
            <Alert marginBottom={4} status={updateResult.type} borderRadius={6}>{updateResult.message}</Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <FormControl isInvalid={errors.password2}>
                    <FormLabel>Password</FormLabel>
                    <Input type='password' {...register("password1", { required: true })} />
                    <FormErrorMessage>Password is required</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.password2}>
                    <FormLabel>Repeat Password</FormLabel>
                    <Input type='password' {...register("password2", { required: true })} />
                    <FormErrorMessage>Password is required</FormErrorMessage>
                </FormControl>

                <Button type="submit" colorScheme='green'>Save</Button>
            </Stack>
        </form>
    </div>


}

export default UpdatePassword;