import Chart from "react-google-charts";
import Constants from "../util/Constants";
import CenteredSpinner from "./CenteredSpinner";

const ActivitySharePieChart = (props) => {

    if(props.data === null) {
        return <CenteredSpinner />
    }

    const options = {
        fontName: "Roboto Condensed",
        chartArea: {
            top: 0,
            left: 10,
            right: 10,
            bottom: 0
        },
        titleTextStyle: {
            fontSize: 24,
            bold: false
        },
        height: "300",
        colors: Constants.ColorWheel
    }

    return <Chart
        chartType="PieChart"
        data={props.data}
        options={options}
        width={"100%"}
        height={"310px"}
        
    />
}

ActivitySharePieChart.defaultProps = {
    data: null,
}

export default ActivitySharePieChart;