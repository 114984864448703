import React from "react";
import CenteredSpinner from "./CenteredSpinner";
import supabase from "../util/Supabase";
import { addDays, format, parse, subDays } from "date-fns";
import Constants from "../util/Constants";
import { MinutesToHoursAndMinutes } from "../util/Helper";

const gridLength = 90;

const getColors = (data) => {
    if (data.length === 0) return "#cdcdcd"
    if (data.length === 1) {
        return data[0].color
    }
    if (data.length === 2) {
        return `linear-gradient(90deg, ${data[0].color} 50%, ${data[1].color} 50%)`
    }

    return "#ff0000"
}

const generateGridData = (data) => {

    const now = new Date()
    const colorLut = {}
    const days = {}
    const refDate = new Date()
    const returnedArray = []
    let nextColorIndex = 0;

    for (let j = 0; j <= gridLength; j++) days[j] = []

    data.forEach(x => {
        const date = parse(x.record_date, 'yyyy-MM-dd', refDate)

        const daysDiff = Math.floor((now - date) / 1000 / 86400)

        let color = '#000000';
        if (x.activity.name in colorLut)
            color = colorLut[x.activity.name];
        else {
            colorLut[x.activity.name] = Constants.ColorWheel[nextColorIndex]
            nextColorIndex = (nextColorIndex + 1) % Constants.ColorWheel.length;
            color = colorLut[x.activity.name];
        }

        days[daysDiff].push({ name: x.activity.name, duration: x.duration_minutes, color: color })
    });

    // Copy dict to arr, then sort
    for (let key in days) returnedArray.push({ offset: key, data: days[key] })
    returnedArray.sort((a, b) => b.offset - a.offset)

    return returnedArray;
}

const FilledGridCell = (props) => {
    let key = props.girdKey;
    let data = props.data;
    let offset = props.offset;

    return (
        <li
            key={"g" + (key++)}
            style={{ color: "#fff", background: getColors(data), borderRadius: 4, margin: 2, textAlign: "center", paddingTop: 10 }}

        >
            {format(subDays(new Date(), offset), 'd')}
            {data.length > 0 && <ul>{data.map((d, j) => <li key={"g" + (key++) + "-" + j}>{d.name} {MinutesToHoursAndMinutes(d.duration)}</li>)}</ul>}
        </li>)

}

const ActivityGrid = (props) => {

    const profile = props.profile;
    const [gridData, setGridData] = React.useState(null);

    React.useEffect(() => {
        const query = async () => {
            const { data } = await supabase.from('records').select('record_date, duration_minutes, activity (name)').eq('profile', profile).gte('record_date', format(subDays(new Date(), gridLength), 'yyyy-MM-dd'))
            setGridData(generateGridData(data))
        }

        query()
    }, [profile])

    if (profile === null || gridData === null) return <CenteredSpinner />

    const grid = []
    let i = 0;

    // Get the month of the first cell
    const firstDate = subDays(new Date(), gridLength);
    const firstMonth = format(firstDate, "MMM")
    const firstDayOfWeek = parseInt(format(firstDate, "i"))

    for (let j = 0; j < 7 - firstDayOfWeek; j++) {
        grid.push(<li key={"g" + (i++)}></li>)
    }

    grid.push(<li key={"g" + (i++)} style={{ borderRadius: 4, margin: 2, textAlign: "center", paddingTop: 10 }}>
        {firstMonth}
    </li>)

    gridData.forEach(v => {

        const cellDate = subDays(new Date(), v.offset);
        const cellMonth = parseInt(format(cellDate, "M"))
        const nextCellMonth = parseInt(format(addDays(cellDate, 1), "M"))

        grid.push(<FilledGridCell data={v.data} gridKey={i} offset={v.offset} />)

        if (cellMonth !== nextCellMonth) {
            // add month column
            for (let j = 0; j < 6; j++) {
                grid.push(<li key={"g" + (i++)}></li>)
            }
            grid.push(<li key={"g" + (i++)} style={{ borderRadius: 4, margin: "2px", textAlign: "center", paddingTop: 10 }}>{format(addDays(cellDate, 1), "MMM")}</li>)
        }

        i++;
    });

    return <div style={{overflow: "hidden"}}>
    <div style={{ maxWidth: 17 * (36 + 4), marginLeft: "auto", marginRight: "auto", clear: "both" }}>
        <div style={{ float: "right" }}>
            <ul className="activityGrid" style={{ fontSize: "80%", margin: "0 auto", display: "grid", gridTemplateRows: "repeat(7, 40px)", gridAutoFlow: "column", gridAutoColumns: "40px", listStyle: "none", width: "100%" }}>
                {grid}
            </ul>
        </div>
    </div></div>
}

ActivityGrid.defaultProps = {
    profile: null
}

export default ActivityGrid