
import React from "react"
import {
    Button,
    HStack,
} from '@chakra-ui/react'
import NarrowContent from '../components/NarrowContent';
import supabase from "../util/Supabase";
import {  useNavigate } from "react-router-dom";
import { Routes } from "../util/Routes";

const Logout = (props) => {

    const navigate = useNavigate();

    const onLogout = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.warn(error);
        } else {
            navigate(Routes.Login)
            console.log("logged out")
        }
    }

    return <NarrowContent>



        <HStack justifyContent="space-between">
            <Button onClick={onLogout}>Logout</Button>
        </HStack>

    </NarrowContent>
}

export default Logout;