import CenteredSpinner from "./CenteredSpinner";
import React from "react";
import supabase from "../util/Supabase";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Button, ButtonGroup, Table, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format, parse } from "date-fns";


const MostVisitedTable = (props) => {

    const profile = props.profile;
    const [data, setData] = React.useState([]);
    const [sorting, setSorting] = React.useState([])

    React.useEffect(() => {

        const queryByMonth = async () => {
            const { data, error } = await supabase.from('most_visited_by_user').select().eq('profile', profile)

            if (error === null) {
                setData(data);
            } else {
                console.warn(error)
            }
        }

        queryByMonth();

    }, [profile]);





    const columns = [
        {
            accessorKey: 'rank',
            header: () => 'Rank',
            footer: props => props.column.id,
            sortDescFirst: true,
            size: 10,

        },
        {
            accessorKey: 'name',
            header: () => 'Region',
            cell: d => {
                return <>{d.getValue()}<br />{d.row.original.array_agg.map((v, i) => <Tag size="sm" me={1} mt={1}>{v}</Tag>)}</>
            },
            footer: props => props.column.id,
            size: 300
        },
        {
            accessorKey: 'cnt',
            id: 'cnt',
            header: () => '# Records',
            footer: props => props.column.id,
            size: 10,
        },
        {
            accessorKey: 'first_visited',
            header: 'First Visited',
            cell: d => format(parse(d.getValue(), "yyyy-MM-dd", new Date()), "dd.MM.yyyy"),
            sortingFn: 'datetime',
            size: 10,
        },
    ]

    const table = useReactTable({
        data,
        columns,
        state: { sorting, },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetPageIndex: false, //turn off auto reset of pageIndex
        debugTable: true,
    })

    if (data.length === 0) {
        return <CenteredSpinner />
    }

    console.log("can prev", table.getCanPreviousPage())

    return (
        <>
            <Table size="sm">
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <Th key={header.id} colSpan={header.colSpan} style={{
                                        width: header.getSize(),
                                    }}>
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : ''
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                                ? 'Sort descending'
                                                                : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' 🔼',
                                                    desc: ' 🔽',
                                                }[header.column.getIsSorted()] ?? null}
                                            </div>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table
                        .getRowModel()
                        .rows.slice(0, 10)
                        .map(row => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <Td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                </Tbody>
            </Table>
            <div style={{display: "flex", width: "100%", justifyContent:"center"}}>
            <ButtonGroup my={3}>
                <Button onClick={() => table.getCanPreviousPage() ? table.firstPage() : undefined}>{'<<'}</Button>
                <Button onClick={() => table.getCanPreviousPage() ? table.previousPage() : undefined}> {'<'}</Button>
                <Button onClick={() => table.getCanNextPage() ? table.nextPage() : undefined}>{'>'}</Button>
                <Button onClick={() => table.getCanNextPage() ? table.lastPage() : undefined}>{'>>'}</Button>
            </ButtonGroup>
            </div>
        </>
    )
}

MostVisitedTable.defaultProps = {
    data: null,
}

export default MostVisitedTable;