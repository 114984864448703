
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Stack,
    Button,
    HStack,
    FormErrorMessage,
} from '@chakra-ui/react'
import CenteredSpinner from '../components/CenteredSpinner';
import React from 'react';
import supabase from '../util/Supabase';
import { useForm } from 'react-hook-form';
import { subDays } from 'date-fns';

const RecordForm = (props) => {

    const [activities, setActivities] = React.useState(null);
    const [suggestedRegions, setSuggestedRegions] = React.useState(null);
    const [suggestedAreas, setSuggestedAreas] = React.useState(null);
    const [isSaving, setIsSaving] = React.useState(false)
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ defaultValues: props.defaultData });

    React.useEffect(() => {
        const queryActivities = async () => {
            const { data, error } = await supabase.from('activities').select().order('name', { ascending: true })

            if (error === null) {
                setActivities(data);
            } else {
                console.warn(error)
            }
        }

        queryActivities();

    }, []);

    const onUpdate = async (field, val) => {
        if (field === "region") {
            const { data, error } = await supabase.from('regions').select().ilike('name', `%${val}%`).order('name', { ascending: true }).limit(5)
            if (error !== null) alert(error)
            setSuggestedRegions(data)
        } else if (field === "area") {
            const { data, error } = await supabase.from('distinct_area_region_map').select().ilike('area_name', `%${val}%`).order('area_name', { ascending: true }).limit(5)
            if (error !== null) alert(error)
            setSuggestedAreas(data)
        }
    }

    const onSuggestedValue = (field, val) => {
        if (field === "region") {
            setValue("region", val.id, { shouldValidate: true })
            setValue("regionHelper", val.name, { shouldValidate: true })
            setSuggestedRegions(null)
        } else if (field === "area") {
            setValue("area", val.area_name, { shouldValidate: true })
            setValue("region", val.region_id, { shouldValidate: true })
            setValue("regionHelper", val.region_name, { shouldValidate: true })
            setSuggestedAreas(null)
        }
    }

    const onInternalSubmit = async (formData) => {
        setIsSaving(true)
        await props.onSubmit(formData)
        setIsSaving(false)
    }

    if (activities === null) {
        return <CenteredSpinner />
    }

    return (
        <form onSubmit={handleSubmit(onInternalSubmit)}>
            <Stack spacing={6}>
                <FormControl isInvalid={errors?.actitity !== undefined}>
                    <FormLabel>Activity</FormLabel>
                    <Select placeholder='Select activity'  {...register("activity", { required: true })}>
                        {activities.map(v => <option key={"a-" + v.id} value={v.id}>{v.name}</option>)}
                    </Select>
                </FormControl>

                <FormControl isInvalid={errors?.area !== undefined}>
                    <FormLabel>Area / Location / Gym</FormLabel>
                    <Input type='text'  {...register("area", { required: true })} onChange={(e) => onUpdate("area", e.target.value)} />
                    <FormErrorMessage>Area is required.</FormErrorMessage>
                </FormControl>

                {suggestedAreas !== null && (
                    <Stack>
                        {suggestedAreas.map((v, i) =>
                            <Button variant="outline" key={`area-sug-${i}`} onClick={() => onSuggestedValue("area", v)}>{v.area_name} ({v.region_name})</Button>
                        )}
                    </Stack>
                )}

                <FormControl isInvalid={errors?.region !== undefined}>
                    <FormLabel>Region</FormLabel>
                    <Input type='text'  {...register("regionHelper", { required: true })} onChange={(e) => onUpdate("region", e.target.value)} />
                    <input type='hidden' {...register("region", { required: true })} />
                    <FormErrorMessage>Region is required.</FormErrorMessage>
                </FormControl>

                {suggestedRegions !== null && (
                    <Stack>
                        {suggestedRegions.map((v, i) =>
                            <Button variant="outline" key={`reg-sug-${i}`} onClick={() => onSuggestedValue("region", v)}>{v.name}</Button>
                        )}
                    </Stack>
                )}
                <Stack>
                    <HStack verticalAlign={"top"}>
                        <FormControl isInvalid={errors?.date !== undefined}>
                            <FormLabel>Date</FormLabel>
                            <Input type='date'  {...register("date", { required: true })} />
                            <FormErrorMessage>Date is required.</FormErrorMessage>
                        </FormControl>
                        <Button alignSelf="end" onClick={() => setValue("date", subDays(new Date(), 1).toISOString().split('T')[0])}>Yesterday</Button>
                        <Button alignSelf="end" onClick={() => setValue("date", new Date().toISOString().split('T')[0])}>Today</Button>
                    </HStack>
                </Stack>

                <Stack>
                    <HStack>
                        <FormControl isInvalid={errors?.hours !== undefined}>
                            <FormLabel>Hours</FormLabel>
                            <Input type='number'  {...register("hours", { required: true })} />
                            <FormErrorMessage>Hours is required.</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors?.minutes !== undefined}>
                            <FormLabel>Minutes</FormLabel>
                            <Input type='number'  {...register("minutes", { required: true })} />
                            <FormErrorMessage>Minutes is required.</FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <Stack>
                        <HStack>
                            <Button flex="1" onClick={() => setValue("hours", 1)}>1h</Button>
                            <Button flex="1" onClick={() => setValue("hours", 2)}>2h</Button>
                            <Button flex="1" onClick={() => setValue("hours", 3)}>3h</Button>
                            <Button flex="1" onClick={() => setValue("hours", 4)}>4h</Button>
                            <Button flex="1" onClick={() => setValue("hours", 5)}>5h</Button>
                            <Button flex="1" onClick={() => setValue("hours", 6)}>6h</Button>
                        </HStack>
                        <HStack>
                            <Button flex="1" onClick={() => setValue("minutes", 0)}>0min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 5)}>5min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 10)}>10min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 15)}>15min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 20)}>20min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 25)}>25min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 30)}>30min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 40)}>40min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 45)}>45min</Button>
                            <Button flex="1" onClick={() => setValue("minutes", 50)}>50min</Button>
                        </HStack>
                    </Stack>
                </Stack>
                <FormControl>
                    <Textarea placeholder='Comment - you may leave this empty.'  {...register("comment", { required: false })} />
                </FormControl>

                <Button type="submit" colorScheme='green' isLoading={isSaving} loadingText='Saving...'>Save</Button>
            </Stack>

        </form>
    )
}

RecordForm.defaultProps = {
    onSubmit: async (formData) => console.log("onSubmit:", formData),
    defaultData: { activity: undefined, region: undefined, regionHelper: undefined, date: undefined, hours: 0, minutes: 0, comment: undefined }
}

export default RecordForm;
