
import NarrowContent from '../components/NarrowContent';
import UpdatePersonalInformation from '../components/UpdatePersonalInformation';
import UpdatePassword from '../components/UpdatePassword';

const Settings = (props) => {
    return <NarrowContent>
        
        <UpdatePersonalInformation style={{marginBottom: 32}} />

        <UpdatePassword />
        
    </NarrowContent>


}

export default Settings;