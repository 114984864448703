import Constants from "../util/Constants";
import CenteredSpinner from "./CenteredSpinner";
import React from "react";
import supabase from "../util/Supabase";
import { subMonths } from "date-fns";
import { MinutesToHoursAndMinutes } from "../util/Helper";
import ReactECharts from 'echarts-for-react';

const maxActivities = 5;

const transform = (data) => {

    const monthDict = {}
    const totalSums = {}
    let d = new Date();

    // Use 13 month to include the current month and the complete month 1 year ago
    for (let j = 0; j < 13; j++) {
        const key = d.getFullYear() + "-" + (d.getMonth() + 1);
        monthDict[key] = {};
        d = subMonths(d, 1)
    }

    // Fill two dictionaries
    // 1) Which activities exist in which month
    // 2) What are the total durations for all activities
    data.forEach(v => {
        monthDict[`${v.record_year}-${v.record_month}`][v.activity_name] = v.sum_minutes;

        if (!(v.activity_name in totalSums))
            totalSums[v.activity_name] = 0;

        totalSums[v.activity_name] += v.sum_minutes;
    })

    // Sort the total duration descending, then truncate by maxActivities
    const totalSumsArray = []
    const truncatedSumsArray = []
    for (let k in totalSums) totalSumsArray.push({ key: k, sum_minutes: totalSums[k] })
    totalSumsArray.sort((a, b) => b.sum_minutes - a.sum_minutes)

    for (let j = 0; j < totalSumsArray.length; j++) {
        if (j < maxActivities) {
            truncatedSumsArray.push(totalSumsArray[j]);
        } else {
            if (truncatedSumsArray.length === maxActivities + 1)
                truncatedSumsArray[maxActivities].sum_minutes += totalSumsArray[j].sum_minutes;
            else
                truncatedSumsArray.push({ key: "Other", sum_minutes: totalSumsArray[j].sum_minutes });
        }

    }

    // Create list structures for plotting
    const headers = ['Month']
    truncatedSumsArray.forEach(v => headers.push(v.key))
    const ret = [headers]

    for (let monthKey in monthDict) {

        const month = monthDict[monthKey];
        const row = [monthKey]

        for (let j = 0; j < totalSumsArray.length; j++) {

            if (row.length < maxActivities + 1) {
                // Non-truncated activities
                if (totalSumsArray[j].key in month)
                    row.push(monthDict[monthKey][totalSumsArray[j].key]);
                else
                    row.push(0);
            } else {
                // Truncated activities - either add to existing "other"-column, or create a new other-column
                if (row.length === maxActivities + 2) {
                    if (totalSumsArray[j].key in monthDict[monthKey]) {
                        row[maxActivities + 1] += monthDict[monthKey][totalSumsArray[j].key]
                    }
                } else {
                    if (totalSumsArray[j].key in monthDict[monthKey]) {
                        row.push(monthDict[monthKey][totalSumsArray[j].key])
                    } else {
                        row.push(0)
                    }
                }

            }
        }
        ret.push(row)
    }

    const returnedData = []
    const legend = []

    for (let a = 1; a < maxActivities + 2; a++) {
       
        const d = []
        for(let j=1; j<ret.length; j++) {
            d.push(ret[j][a])
        }

        legend.push(ret[0][a])

        returnedData.push({
            name: ret[0][a],
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: { width: 0 },
            showSymbol: false,
            areaStyle: { opacity: 0.8, color: Constants.ColorWheel[(a - 1) % Constants.ColorWheel.length] },
            emphasis: { focus: 'series' },
            data: d.reverse()
        })
    }

    return {
        xAxis: Object.keys(monthDict).reverse(),
        legend: legend,
        data: returnedData
    };
}




const ActivityByMonth = (props) => {

    const profile = props.profile;
    const [data, setData] = React.useState(null);

    React.useEffect(() => {

        const queryByMonth = async () => {
            const { data, error } = await supabase.from('activity_by_month').select().eq('profile', profile)

            if (error === null) {
                setData(transform(data));
            } else {
                console.warn(error)
            }
        }

        queryByMonth();

    }, [profile]);

    if (data === null) {
        return <CenteredSpinner />
    }


    const options = {
        color: Constants.ColorWheel,
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } },
            valueFormatter: (value) => MinutesToHoursAndMinutes(value)
        },
        legend: {
            data: data.legend
        },
        grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: data.xAxis
            }
        ],
        yAxis: [{ type: 'value', axisLabel: { formatter: (value) => MinutesToHoursAndMinutes(value) }}],
        series: data.data,
        textStyle: {
            fontFamily: "Roboto Condensed",
            color: "#000"
        }
    };

    return <ReactECharts option={options} style={ {height: '400px'}} />
}

ActivityByMonth.defaultProps = {
    data: null,
}

export default ActivityByMonth;