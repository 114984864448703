const Constants = {
    ColorWheel: [
        "#CC7A7A",
        "#7AADCC",
        "#CCCB7A",
        "#677177",
        "#8C7777",
        "#4D3636",
        "#777767"
    ],
    ContainerMaxWidth: "1024px",

}

export default Constants
