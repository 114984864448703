
import React from 'react';
import supabase from '../util/Supabase';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../util/Routes';
import CenteredSpinner from '../components/CenteredSpinner';
import { MinutesToHoursAndMinutes } from '../util/Helper';
import { Button, HStack } from '@chakra-ui/react';

const DeleteRecord = (props) => {

    const navigate = useNavigate();
    const { recordId } = useParams();

    const [record, setRecord] = React.useState(null)

    React.useEffect(() => {
        const f = async () => {
            const { data, error } = await supabase.from('records').select("*, region (*), activity (*)").eq('id', recordId)
            if (error !== null)
                console.warn(error)

            if (data.length !== 1) {
                console.warn("Unexpected number of results:", data)
                return
            }

            const reshaped = {
                activity: data[0].activity,
                region: data[0].region.id,
                regionHelper: data[0].region.name,
                area: data[0].area,
                date: data[0].record_date,
                hours: Math.floor(data[0].duration_minutes / 60),
                minutes: data[0].duration_minutes % 60,
                comment: data[0].comment
            }

            setRecord(reshaped)
        }

        f()
    }, [recordId])

    const onSubmit = async (formData) => {
        const updateData = {
            id: recordId,
            activity: parseInt(formData.activity),
            region: parseInt(formData.region),
            area: formData.area,
            record_date: formData.date,
            duration_minutes: parseInt(formData.hours) * 60 + parseInt(formData.minutes),
            comment: formData.comment
        }

        try {
            const { error } = await supabase.from('records').update(updateData).eq('id', recordId)

            if (error !== null)
                console.warn(error)

            navigate(Routes.Statistics, { state: { toast: "Record updated successfully.", id: recordId } })
        } catch (e) {
            console.warn(e)
        }
    }

    if (record === null) {
        return <CenteredSpinner />
    }

    return (
        <>
            <h2>Delete Record</h2>
            
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <p>Please confirm that you want to delete the record below:</p>
            </div>

            <div style={{paddingTop: 10, paddingBottom: 20}}>
                <p>{record.activity.name} ({MinutesToHoursAndMinutes(record.hours * 60 + record.minutes, true)})</p>
                <p><strong>{record.area}</strong> in <strong>{record.regionHelper}</strong></p>
            </div>
            <HStack justify="end">
            <Button  colorScheme='orange'>Delete</Button>
            </HStack>
        </>
    )
}

export default DeleteRecord;