import React from "react";
import { Grid, GridItem, useToast } from "@chakra-ui/react";
import { useStoreState } from "easy-peasy";
import supabase from "../util/Supabase";

import ActivitySharePieChart from "../components/ActivitySharePieChart";
import LatestActivitiesTable from "../components/LatestActivitiesTable";
import CenteredSpinner from "../components/CenteredSpinner";
import ActivityGrid from "../components/ActivityGrid";
import ActivityByMonth from "../components/ActivityByMonth";
import { useLocation, useParams } from "react-router-dom";
import MostVisitedTable from "../components/MostVisitedTable";

const transformToPieChartData = (data) => {

    const max = 6;
    const res = [["Activity", "Percentage"]]
    let other = 0;
    data.forEach(el => {

        if (res.length >= max)
            other += el.sum_minutes;
        else
            res.push([el.activity_name, el.sum_minutes])
    });

    if (other > 0) {
        res.push(["Other", other])
    }

    return res;
}

const Statistics = (props) => {

    const auth = useStoreState((state) => state.auth)
    const { state } = useLocation()
    const toast = useToast()
    const params = useParams();

    const [sharesLast90, setSharesLast90] = React.useState();
    const [sharesAll, setSharesAll] = React.useState();

    const profile = (params.profileId !== undefined) ? params.profileId : auth.session.user.id;

    React.useEffect(() => {
        const querySharesLast30 = async () => {
            const { data, error } = await supabase.from('activity_by_profile_90days').select().eq('profile', profile).order('sum_minutes', { ascending: false })

            if (error === null) {
                setSharesLast90(transformToPieChartData(data));
            } else {
                console.warn(error)
            }
        }

        const querySharesAll = async () => {
            const { data, error } = await supabase.from('activity_by_profile').select().eq('profile', profile).order('sum_minutes', { ascending: false })

            if (error === null) {
                setSharesAll(transformToPieChartData(data));
            } else {
                console.warn(error)
            }
        }

        querySharesLast30();
        querySharesAll();

        if (state !== null && state.toast !== undefined) {
            if (!toast.isActive(state.id)) { // prevent double toast in strict mode
                toast({
                    id: state.id,
                    title: state.toast,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            }
        }

    }, [profile]);

    return <>
        <h2>Recent Activities</h2>

        <ActivityGrid profile={profile} />

        <Grid templateColumns={{ base: '1fr', lg: 'repeat(7, 1fr)' }} gap={{ base: 0, lg: 4 }} marginTop={4}>
            <GridItem colSpan={4}>
                <LatestActivitiesTable user={profile} showDelete={true} showEdit={true} showClone={true} />
            </GridItem>

            <GridItem colSpan={3} borderLeft="gray.200" borderLeftWidth={{ base: 0, lg: "1px" }} paddingLeft={{ base: 0, lg: 4 }}>

                <h2>Time Spent in the Last 90 Days</h2>
                <div>
                    {sharesLast90 === null ? <CenteredSpinner /> : (
                        <ActivitySharePieChart data={sharesLast90} />
                    )}
                </div>

                <h2>Time Spent Since Beginning</h2>
                <div>
                    {sharesAll === null ? <CenteredSpinner /> : (
                        <ActivitySharePieChart data={sharesAll} />
                    )}
                </div>

            </GridItem>
        </Grid>

        <div>
            <h2>Most Visited Regions</h2>
            <MostVisitedTable profile={profile} />
        </div>

        <div>
            <h2>Last 12 Months</h2>
            <ActivityByMonth profile={profile} />
        </div>
    </>

}

export default Statistics;