export const Routes = {
    Home: "/",
    CloneRecord: "/clonerecord/:recordId",
    NewRecord: "/newrecord",
    EditRecord: "/editrecord/:recordId",
    DeleteRecord: "/deleterecord/:recordId",
    NewRegion: "/newregion",
    Statistics: "/statistics",
    Search: "/search",
    Regions: "/regions",
    Settings: "/settings",
    Login: "/login",
    Logout: "/logout",
}

export const GetQueryRoute = (name, param, value) => {
    return Routes[name].replace(`:${param}`, value)
}