import { action, createStore } from "easy-peasy";
import { GetLoginState } from "./Helper";

const store = createStore({
    auth: GetLoginState(null, null, false),


    setAuth: action((state, payload) => {
        state.auth = payload;
        console.log("setAuth()")
    })
})

export default store;