
import { Flex, VStack, Text } from "@chakra-ui/react";
import React from "react";
import CenteredSpinner from "./CenteredSpinner";
import supabase from "../util/Supabase";
import { differenceInDays, format, parse } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../util/Routes";

const query = async () => {
    const { data } = await supabase.from('profile_stats').select()
    return data
}

const UsersList = (props) => {

    const [users, setUsers] = React.useState(null)
    const navigate = useNavigate()

    React.useEffect(() => {
        query().then(d => setUsers(d))
    }, [])

    console.log(users)

    return (
        <>
            <h2>Users</h2>
            {(users === null) ? <CenteredSpinner /> : (
                <VStack>
                    {users.map((v, i) => {
                        return (
                            <div key={`users${i}`} style={{ width: "100%", marginBottom: 6 }} >
                                <Flex direction="column">
                                    <Text fontSize="lg"><Link to={ `${Routes.Statistics}/${v.profile}`}>{v.full_name}</Link></Text>
                                    <Text fontSize="xs">First record: <strong>{format(parse(v.first_record, "yyyy-MM-dd", new Date()), "dd.MM.yyyy")}</strong></Text>
                                    <Text fontSize="xs">Last record: <strong>{format(parse(v.last_record, "yyyy-MM-dd", new Date()), "dd.MM.yyyy")}</strong></Text>
                                    <Text fontSize="xs">
                                        Total: <strong>{v.cnt}</strong>
                                    </Text>
                                    <Text fontSize="xs">in <strong>{Math.round(10 * differenceInDays(parse(v.last_record, "yyyy-MM-dd", new Date()), parse(v.first_record, "yyyy-MM-dd", new Date())) / 365.0) / 10}</strong> years</Text>
                                </Flex>

                            </div>)
                    })}

                </VStack>
            )}
        </>
    )
}

export default UsersList;