import { Container, Flex, Icon, IconButton } from "@chakra-ui/react"
import { LockIcon } from '@chakra-ui/icons'
import Constants from "../util/Constants"
import { NavLink } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { MdHome, MdAdd, MdBarChart, MdSearch, MdLocationPin, MdLogout, MdSettings } from "react-icons/md";
import { Routes } from "../util/Routes";

const NavBar = (props) => {

    const auth = useStoreState((state) => state.auth);

    const name = auth?.profile?.full_name ?? "unknown"

    return (
        <div style={styles.container}>
            <Container maxW={Constants.ContainerMaxWidth}>
                <Flex justifyContent={"space-between"} paddingBottom={1} paddingTop={1}>
                    <span style={styles.title}>/STATS5</span>

                    <div>
                        {auth.session === null ? <LockIcon marginRight={2} /> : (
                            <>
                            <span>Hello {name} </span>
                                <NavLink to={Routes.Home}><IconButton marginRight={"10px"} icon={<Icon as={MdHome} />} size="sm" /></NavLink>
                                <NavLink to={Routes.NewRecord}><IconButton marginRight={"10px"} icon={<Icon as={MdAdd} />} size="sm" /></NavLink>
                                <NavLink to={Routes.Statistics}><IconButton marginRight={"10px"} icon={<Icon as={MdBarChart} />} size="sm" /></NavLink>
                                <NavLink to={Routes.Search}><IconButton marginRight={"10px"} icon={<Icon as={MdSearch} />} size="sm" /></NavLink>
                                <NavLink to={Routes.Regions}><IconButton marginRight={"10px"} icon={<Icon as={MdLocationPin} />} size="sm" /></NavLink>
                                <NavLink to={Routes.Settings}><IconButton marginRight={"10px"} icon={<Icon as={MdSettings} />} size="sm" /></NavLink>
                                <NavLink to={Routes.Logout}><IconButton marginRight={"0px"} icon={<Icon as={MdLogout} />} size="sm" /></NavLink>
                            </>
                        )}
                    </div>
                </Flex>
            </Container>
        </div>
    )
}

const styles = {
    container: {
        background: "#9bb3bf",
    },
    title: {
        fontFamily: "'Roboto Condensed', 'Roboto Mono', monospace",
        fontSize: "140%",
        fontWeight: 400,
        color: "#fff"
    }

}

export default NavBar