
import {
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    FormErrorMessage,
} from '@chakra-ui/react'
import React from 'react';
import supabase from '../util/Supabase';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../util/Routes';

const NewRegion = (props) => {

    const navigate = useNavigate();

    const [suggestedAreas, setSuggestedAreas] = React.useState(null);
    const [isSaving, setIsSaving] = React.useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (formData) => {
        setIsSaving(true)

        const insertData = {
            name: formData.region
        }

        try {
            const { error } = await supabase.from('regions').insert(insertData)
            
            if(error !== null) 
                console.warn("ERROR for", insertData, error)
            else
                navigate(Routes.Regions)

        } catch (e) {
            console.warn(e)
        }


        setIsSaving(false)
    }

    const onUpdate = async (val) => {
        const { data, error } = await supabase.from('regions').select().ilike('name', `%${val}%`).order('name', { ascending: true }).limit(5)
        if(error !== null) alert(error)
        setSuggestedAreas(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
                

                <FormControl isInvalid={errors?.region !== undefined}>
                    <FormLabel>Region</FormLabel>
                    <Input type='text'  {...register("region", { required: true })} onChange={(e) => onUpdate(e.target.value)} />
                    <FormErrorMessage>Region name is required.</FormErrorMessage>
                </FormControl>

                {suggestedAreas !== null && suggestedAreas.length > 0 && (
                    <Stack>
                        <strong>Regions with a similar name:</strong>
                        {suggestedAreas.map((v, i) =>
                            <Button variant="outline" key={`reg-${i}`}>{v.name}</Button>
                        )}
                    </Stack>
                )}


                <Button type="submit" colorScheme='green' isLoading={isSaving} loadingText='Adding New Region...'>Add New Region</Button>
            </Stack>

        </form>
    )
}

export default NewRegion;