
import React from 'react';
import supabase from '../util/Supabase';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../util/Routes';
import RecordForm from '../components/RecordForm';
import CenteredSpinner from '../components/CenteredSpinner';
import { useStoreState } from 'easy-peasy';

const CloneRecord = (props) => {

    const navigate = useNavigate();
    const { recordId } = useParams();
    const auth = useStoreState((state) => state.auth);
    
    const [record, setRecord] = React.useState(null)

    React.useEffect(() => {
        const f = async () => {
            const { data, error } = await supabase.from('records').select("*, region (*)").eq('id', recordId)
            if (error !== null)
                console.warn(error)

            if(data.length !== 1) {
                console.warn("Unexpected number of results:", data)
                return
            }

            const reshaped = { 
                activity: data[0].activity, 
                region: data[0].region.id, 
                regionHelper: data[0].region.name, 
                area: data[0].area, 
                date: data[0].record_date, 
                hours: Math.floor(data[0].duration_minutes / 60), 
                minutes: data[0].duration_minutes % 60, 
                comment: data[0].comment 
            }

            setRecord(reshaped)
        }

        f()
    }, [recordId])

    const onSubmit = async (formData) => {
        const updateData = {
            activity: parseInt(formData.activity),
            region: parseInt(formData.region),
            area: formData.area,
            record_date: formData.date,
            duration_minutes: parseInt(formData.hours) * 60 + parseInt(formData.minutes),
            comment: formData.comment,
            profile: auth.session.user.id,
        }

        try {
            const { error } = await supabase.from('records').insert(updateData)

            if (error !== null)
                console.warn(error)

            navigate(Routes.Statistics, { state: { toast: "Record cloned successfully.", id: recordId } })
        } catch (e) {
            console.warn(e)
        }
    }


    if (record === null) {
        return <CenteredSpinner />
    }

    return (
        <RecordForm onSubmit={onSubmit} defaultData={record} />
    )
}

export default CloneRecord;