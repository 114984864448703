
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Container } from "@chakra-ui/react";
import React from "react";

import Home from "./pages/Home";
import EditRecord from "./pages/EditRecord";
import CloneRecord from "./pages/CloneRecord";
import DeleteRecord from "./pages/DeleteRecord";
import NewRecord from "./pages/NewRecord";
import Login from "./pages/Login";
import Statistics from "./pages/Statistics";
import Regions from "./pages/Regions";
import Search from "./pages/Search";
import NewRegion from "./pages/NewRegion";
import Settings from "./pages/Settings";
import NavBar from "./components/NavBar";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import { useStoreActions } from "easy-peasy";

import Constants from "./util/Constants";
import supabase from "./util/Supabase";
import { Routes } from "./util/Routes";
import Logout from "./pages/Logout";
import { GetLoginState } from "./util/Helper";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: Routes.Home, element: <RequireAuth><Home /></RequireAuth> },
      { path: Routes.EditRecord, element: <RequireAuth><EditRecord /></RequireAuth> },
      { path: Routes.DeleteRecord, element: <RequireAuth><DeleteRecord /></RequireAuth> },
      { path: Routes.CloneRecord, element: <RequireAuth><CloneRecord /></RequireAuth> },
      { path: Routes.NewRecord, element: <RequireAuth><NewRecord /></RequireAuth> },
      { path: Routes.Login, element: <Login /> },
      { path: Routes.Logout, element: <Logout /> },
      { path: `${Routes.Statistics}/:profileId?`, element: <RequireAuth><Statistics /></RequireAuth> },
      { path: Routes.Search, element: <RequireAuth><Search /></RequireAuth> },
      { path: Routes.Settings, element: <RequireAuth><Settings /></RequireAuth> },
      { path: Routes.Regions, element: <RequireAuth><Regions /></RequireAuth> },
      { path: Routes.NewRegion, element: <RequireAuth><NewRegion /></RequireAuth> },
    ]
  },
]);

function App() {

  const [authInitialized, setAuthInitialized] = React.useState(false)
  const setAuth = useStoreActions((actions) => actions.setAuth);

  React.useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log("session:", session)
      if (session !== null) {
        supabase.from('profiles').select().eq('id', session.user.id).then(({ data, errors }) => {
          if (errors !== null) {
            setAuth(GetLoginState(session, data[0], true))
            console.log("User is logged in.")
          } else {
            setAuth(GetLoginState(null, null, true))
            console.warn("Could not get profile data", errors)
          }
        })
      }

      setAuthInitialized(true)
    })

    const { data: { subscription }, } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user !== undefined) {

        /*supabase.from('profiles').select().eq('id', session.user.id).then(({ data, errors }) => {
          if (errors !== null) {
            setProfile(data[0])
            setAuth(session)
            console.log("user is logged in")
          } else {
            console.warn("Could not get profile data", errors)
          }
        })*/
        //console.log("App received onAuthStateChange:loggedIn")
      } else {
        setAuth(GetLoginState(null, null, false))
        console.log("User is not set")
      }
    })
    return () => subscription.unsubscribe()
  }, [])

  if(!authInitialized) {
    console.log("render loader")
    return <span>Loading user data.</span>

  }
console.log("render content")
  return (
    <RouterProvider router={router}>
      <NavBar />
      <Container maxW={Constants.ContainerMaxWidth} paddingTop={4}>
        <Outlet />
      </Container>
    </RouterProvider>
  );
}



export default App;
