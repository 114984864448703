
import { Outlet } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import NavBar from "./NavBar";
import Constants from "../util/Constants";

function Layout() {
    return (
        <>
            <NavBar />
            <Container maxW={Constants.ContainerMaxWidth} paddingTop={4}>
                <Outlet />
            </Container>
        </>);
}



export default Layout;
