
import React from "react"
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Stack,
    Button,
    HStack,
    Alert,
} from '@chakra-ui/react'
import NarrowContent from '../components/NarrowContent';
import { useForm } from 'react-hook-form';
import supabase from "../util/Supabase";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { GetLoginState } from "../util/Helper";

const Login = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loginError, setLoginError] = React.useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const setAuth = useStoreActions((actions) => actions.setAuth);

    console.log(location.state)
    const from = location.state?.from?.pathname || "/";

    const onSubmit = async (formData) => {
        const signInResult = await supabase.auth.signInWithPassword(formData);
        console.log(signInResult)
        if (signInResult.error) {
            console.warn(signInResult.error);
            setLoginError(signInResult.error)
        } else {
            const { data, errors } = await supabase.from('profiles').select().eq('id', signInResult.data.user.id)
            
            if (errors !== null) {
                setAuth(GetLoginState(signInResult.data, data[0], true))

                console.log("User is logged in. Navigating to", from)

                navigate(from, { replace: true });
            } else {
                console.warn("Could not get profile data", errors)
            }

            
        }
    }

    return <NarrowContent>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
                {loginError !== null && (
                    <Alert status="error" borderRadius={6}>{loginError.message}</Alert>
                )}
                <FormControl isInvalid={errors.email}>
                    <FormLabel>E-Mail Address</FormLabel>
                    <Input type='text' {...register("email", { required: true })} />
                    <FormErrorMessage>E-mail address is required</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.password}>
                    <FormLabel>Password</FormLabel>
                    <Input type='password' {...register("password", { required: true })} />
                    <FormErrorMessage>Password is required</FormErrorMessage>
                </FormControl>

                <HStack justifyContent="space-between">
                    <Button type="submit">Forgot Password</Button>
                    <Button type="submit" colorScheme='green'>Login</Button>
                </HStack>
            </Stack>
        </form>
    </NarrowContent>
}

export default Login;