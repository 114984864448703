import React from "react"
import supabase from "../util/Supabase"
import { useStoreState } from "easy-peasy"
import ActivityDetailRow from "./ActivityDetailRow"
import CenteredSpinner from "./CenteredSpinner"
import { Button, HStack } from "@chakra-ui/react"


const query = (async (user = null, queryPage = 0, numberOfRows = 10) => {
    let data = null, error = null;

    if (user !== null) {
        ({ data, error } = await supabase.from('records')
            .select('*, activity, region, activities (name), regions (name), profiles (full_name)')
            .eq('profile', user)
            .order('record_date', { ascending: false })
            .range(queryPage * numberOfRows, (queryPage + 1) * numberOfRows - 1))
    } else {
        ({ data, error } = await supabase.from('records')
            .select('*, activity, region, activities (name), regions (name), profiles (full_name)')
            .order('record_date', { ascending: false })
            .range(queryPage * numberOfRows, (queryPage + 1) * numberOfRows - 1))

    }
    if (error !== null) {
        console.warn(error)
        return []
    } else {
        return data
    }
})


const LatestActivitiesTable = (props) => {

    const auth = useStoreState((state) => state.auth)

    const [tableData, setTableData] = React.useState({ page: 0, data: null });

    React.useEffect(() => {
        console.log(props.user)
        query(props.user).then(d => setTableData({ page: 0, data: d }))
    }, [])

    const handleNext = async () => {
        setTableData({ page: tableData.page + 1, data: await query(props.user, tableData.page + 1) })
    }

    const handlePrev = async () => {
        setTableData({ page: tableData.page - 1, data: await query(props.user, tableData.page - 1) })
    }

    return <>
        <h2>Latest Records</h2>{tableData.data === null ? <CenteredSpinner /> :

            <>
                {tableData.data.map((v, i) => {
                    return (
                        <ActivityDetailRow
                            key={"adr-" + i}
                            id={v.id}
                            date={new Date(v.record_date)}
                            user={v.profiles.full_name}
                            region={v.regions.name}
                            name={v.area}
                            activity={v.activities.name}
                            minutes={v.duration_minutes}
                            clonable={props.showClone && true}
                            editable={props.showEdit && v.profile === auth.session.user.id}
                            deletable={props.showDelete && v.profile === auth.session.user.id}
                            showUser={props.showUser}
                        />)
                })}
                <HStack justify="space-between">
                    <Button disabled={tableData.page <= 0} onClick={handlePrev}>Previous</Button>
                    <span>Page {tableData.page + 1}</span>
                    <Button onClick={handleNext}>Next</Button>
                </HStack>
            </>
        }
    </>
}

LatestActivitiesTable.defaultProps = {
    user: null,
    showUser: false,
    showClone: false,
    showEdit: false,
    showDelete: false,
}

export default LatestActivitiesTable