

export const MinutesToHoursAndMinutes = (minutes, withUnits = false) => {
    if (withUnits)
        return Math.floor(minutes / 60) + "h " + minutes % 60 + "min"
    else
        return Math.floor(minutes / 60) + ":" + String(minutes % 60).padStart(2, '0')
}

export const MinutesToDaysHoursAndMinutes = (minutes, withUnits = false) => {

    const days = Math.floor(minutes / 60 / 24)
    const rem = minutes - days * 24 * 60

    if (withUnits) {
        if (days === 0)
            return Math.floor(rem / 60) + "h " + rem % 60 + "min"
        else
            return days + "d " + Math.floor(rem / 60) + "h " + rem % 60 + "min"
    }
    else {
        if (days === 0)
            return Math.floor(rem / 60) + ":" + String(rem % 60).padStart(2, '0')
        else
            return days + ":" + Math.floor(rem / 60) + ":" + String(rem % 60).padStart(2, '0')
    }
}

export const GetLoginState = (session, profile, isLoggedIn) => {
    return {
        session: session,
        profile: profile,
        isLoggedIn: isLoggedIn
    }
}