
import NarrowContent from '../components/NarrowContent';

const Search = (props) => {
    return <NarrowContent>
        
        <h2>Search</h2>

        <p>Search is not implemented yet.</p>
        
    </NarrowContent>


}

export default Search;